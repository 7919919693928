<template>
  <v-card
    id="time-extension"
    flat
  >
    <v-card-title>Time extension</v-card-title>
    <v-card-text>
      <v-radio-group
        v-model="mandatoryTime"
        row
        mandatory
        @change="changeRadioBtn"
      >
        <v-radio
          label="Static"
          :ripple="true"
          value="static"
          color="color_green"
        />
        <v-radio
          label="Dynamic"
          :ripple="true"
          value="dynamic"
          color="color_green"
        />
      </v-radio-group>
      <div v-if="mandatoryTime === 'static'">
        <v-row>
          <v-col>
            <v-menu
              ref="menuStart"
              v-model="menuStart"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-overflow
              min-width="100px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="Start date"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  required
                  :rules="date_rules"
                  :counter="12"
                  v-on="on"
                />
              </template>
              <v-date-picker
                ref="startPicker"
                v-model="startDate"
                no-title
                :show-current="false"
                min="1900-01-01"
                @change="saveStart"
              />
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              ref="menuStop"
              v-model="menuStop"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-overflow
              min-width="100px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="stopDate"
                  label="Stop date"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  required
                  :rules="date_rules"
                  :counter="12"
                  v-on="on"
                />
              </template>
              <v-date-picker
                ref="stopPicker"
                v-model="stopDate"
                no-title
                :show-current="false"
                :min="startDate"
                @change="saveStop"
              />
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="12">
            <v-btn-toggle
              v-model="toggle_exclusive"
              mandatory
            >
              <v-btn @click="clickMonth">
                <v-icon left>
                  mdi-calendar-month
                </v-icon>
                Months
              </v-btn>
              <v-btn @click="clickWeek">
                <v-icon>mdi-calendar-range</v-icon>
                Weeks
              </v-btn>
              <v-btn @click="clickDay">
                <v-icon>mdi-calendar-today</v-icon>
                Days
              </v-btn>
              <v-btn @click="clickHour">
                <v-icon>mdi-hours-24</v-icon>
                Hours
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="geoserver_data.startTime"
              outlined
              dense
              full-width
              :label="`${dynamicTime} in the past`"
              :suffix="`/${dynamicTime}`"
              :rules="time_rules"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="geoserver_data.stopTime"
              outlined
              dense
              full-width
              :label="`${dynamicTime} in the future`"
              :suffix="`/${dynamicTime}`"
              :rules="time_rules"
              @keydown="dynamicSaveStop()"
            />
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { required, manageLenghtError, getRegExp } from '@/formRules.js';

export default {
  data() {
    return {
      mandatoryTime: '',
      startDate: null,
      menuStart: false,
      stopDate: null,
      menuStop: false,
      toggle_exclusive: undefined,
      dynamicTime: '',
      date_rules: [...required],
      time_rules: [
        ...required,
        ...manageLenghtError(5),
        ...getRegExp('numbers_only')
      ]
    };
  },
  computed: {
    ...mapState(['geoserver_data'])
  },
  watch: {
    menuStart(val) {
      val && setTimeout(() => (this.$refs.startPicker.activePicker = 'YEAR'));
    },
    menuStop(val) {
      val && setTimeout(() => (this.$refs.stopPicker.activePicker = 'YEAR'));

      if (
        !val &&
        this.geoserver_data.startTime &&
        this.geoserver_data.stopTime
      ) {
        this.$emit('settedTime');
      }
    }
  },

  created() {
    if (this.geoserver_data.isModifingWorkspace == true) {
      if (this.geoserver_data.workspace.date_type == 'static') {
        this.geoserver_data.dateType = 'static';
        this.mandatoryTime = 'static';
        this.toggle_exclusive = undefined;
        this.dynamicTime = '';
      } else if (this.geoserver_data.workspace.date_type == 'dynamic_weeks') {
        this.geoserver_data.dateType = 'dynamic_weeks';
        this.mandatoryTime = 'dynamic';
        this.dynamicTime = 'Weeks';
        this.toggle_exclusive = 1;
      } else if (this.geoserver_data.workspace.date_type == 'dynamic_days') {
        this.geoserver_data.dateType = 'dynamic_days';
        this.mandatoryTime = 'dynamic';
        this.dynamicTime = 'Days';
        this.toggle_exclusive = 2;
      } else if (this.geoserver_data.workspace.date_type == 'dynamic_hours') {
        this.geoserver_data.dateType = 'dynamic_hours';
        this.mandatoryTime = 'dynamic';
        this.dynamicTime = 'Hours';
        this.toggle_exclusive = 3;
      } else {
        this.geoserver_data.dateType = 'dynamic';
        this.mandatoryTime = 'dynamic';
        this.dynamicTime = 'Months';
        this.toggle_exclusive = 0;
      }

      this.startDate = this.geoserver_data.workspace.start_time;
      this.stopDate = this.geoserver_data.workspace.stop_time;
      this.geoserver_data.startTime = this.geoserver_data.workspace.start_time;
      this.geoserver_data.stopTime = this.geoserver_data.workspace.stop_time;
    } else {
      this.mandatoryTime = 'static';
      this.toggle_exclusive = undefined;
      this.dynamicTime = '';
    }
  },
  methods: {
    saveStart(date) {
      this.$refs.menuStart.save(date);
      this.geoserver_data.dateType = 'static';
      this.geoserver_data.startTime = date;
    },
    saveStop(date) {
      this.$refs.menuStop.save(date);
      this.geoserver_data.dateType = 'static';
      this.geoserver_data.stopTime = date;
    },
    dynamicSaveStop() {
      this.$emit('settedTime');
    },
    clickMonth() {
      this.dynamicTime = 'Months';
      this.geoserver_data.dateType = 'dynamic_months';
    },
    clickWeek() {
      this.dynamicTime = 'Weeks';
      this.geoserver_data.dateType = 'dynamic_weeks';
    },
    clickDay() {
      this.dynamicTime = 'Days';
      this.geoserver_data.dateType = 'dynamic_days';
    },
    clickHour() {
      this.dynamicTime = 'Hours';
      this.geoserver_data.dateType = 'dynamic_hours';
    },

    changeRadioBtn() {
      this.startDate = null;
      this.stopDate = null;
      this.geoserver_data.startTime = '';
      this.geoserver_data.stopTime = '';

      if (this.geoserver_data.dateType !== 'static') {
        this.geoserver_data.dateType = 'static';
      } else {
        if (this.dynamicTime == 'Months') {
          this.geoserver_data.dateType = 'dynamic_months';
        } else if (this.dynamicTime == 'Weeks') {
          this.geoserver_data.dateType = 'dynamic_weeks';
        } else if (this.dynamicTime == 'Days') {
          this.geoserver_data.dateType = 'dynamic_days';
        } else if (this.dynamicTime == 'Hours') {
          this.geoserver_data.dateType = 'dynamic_hours';
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
